import React from "react";
import { Root, Head, withSiteData } from "react-static";
import { Link } from "@reach/router";
//

import "./app.css";

const App = withSiteData(({ title }) => (
  <Root>
    <Head>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{title}</title>
      <link rel="stylesheet" href="https://unpkg.com/tachyons@4.10.0/css/tachyons.min.css" />
      <body className="sans-serif" />
    </Head>

    <div className="banner">
      <div className="white ma0 f3 center">coming soon</div>
    </div>
  </Root>
));

export default App;
